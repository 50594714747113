import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { convertTimestamp } from 'utils/date';
import { useIntl } from 'react-intl';
import { green, blue, gray } from '@ant-design/colors';

interface MemoryChartProps {
  allocatedMemory?: number[];
  usedMemory?: number[];
  timeData?: string[];
}

const MemoryChart: React.FC<MemoryChartProps> = ({
  allocatedMemory = [],
  usedMemory = [],
  timeData = [],
}) => {
  const intl = useIntl();

  const isSinglePoint = allocatedMemory.length === 1 && usedMemory.length === 1;

  const [chartOptions, setChartOptions] = useState<ApexOptions>({
    chart: {
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
      },
    },
    colors: [blue[6], green[6]],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: isSinglePoint ? 0 : 3,
    },
    markers: {
      size: isSinglePoint ? 8 : 0,
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    grid: {
      padding: {
        top: 60,
        bottom: 40,
        left: 60,
        right: 40,
      },
    },
    title: {
      text: intl.formatMessage({ id: 'metrics.sparkDriverMemoryUsage' }),
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: gray[13],
      },
    },
    xaxis: {
      type: 'datetime',
      categories: timeData,
      labels: {
        formatter: (value: string) => {
          const isoString = new Date(parseInt(value)).toISOString();
          const { dateFormatted, timeFormatted } = convertTimestamp(isoString);
          return `${dateFormatted} ${timeFormatted}`;
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (val: number) => `${val.toFixed(1)} MB`,
      },
      title: {
        text: `${intl.formatMessage({ id: 'metrics.memory' })} (MB)`,
      },
    },
    tooltip: {
      shared: false,
      y: {
        formatter: (val: number) => `${val} MB`,
      },
    },
    fill: {
      type: 'none',
    },
  });

  const [series, setSeries] = useState([
    {
      name: intl.formatMessage({ id: 'metrics.allocatedMemory' }),
      data: allocatedMemory,
    },
    {
      name: intl.formatMessage({ id: 'metrics.usedMemory' }),
      data: usedMemory,
    },
  ]);

  useEffect(() => {
    setSeries([
      {
        name: intl.formatMessage({ id: 'metrics.allocatedMemory' }),
        data: allocatedMemory,
      },
      {
        name: intl.formatMessage({ id: 'metrics.usedMemory' }),
        data: usedMemory,
      },
    ]);
  }, [allocatedMemory, usedMemory]);

  useEffect(() => {
    setChartOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: timeData,
      },
      stroke: {
        ...prevOptions.stroke,
        width: isSinglePoint ? 0 : 3,
      },
      markers: {
        ...prevOptions.markers,
        size: isSinglePoint ? 8 : 0,
      },
    }));
  }, [timeData, isSinglePoint]);

  return (
    <section>
        <ReactApexChart
          options={chartOptions}
          series={series}
          type="line"
          height={'650px'}
        />
    </section>
  );
};

export default MemoryChart;
