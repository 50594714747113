import {
  formatDuration,
  format,
  parseISO,
  millisecondsInHour,
  millisecondsInMinute,
  millisecondsInSecond,
} from 'date-fns';

function convertDurationToShortTimeUnits(
  duration: string,
  useLeastLetters: boolean = false
) {
  return duration
    .replace(' seconds', useLeastLetters ? ' s' : ' sec')
    .replace(' second', useLeastLetters ? ' s' : ' sec')
    .replace(' minutes', useLeastLetters ? ' m' : ' mins')
    .replace(' minute', useLeastLetters ? ' m' : ' min')
    .replace(' hours', useLeastLetters ? ' h' : ' hrs')
    .replace(' hour', useLeastLetters ? ' h' : ' hr');
}

export function formatDurationToHMS({
  duration,
  shorthand,
  useLeastLetters = false,
}: {
  duration: Duration;
  shorthand?: boolean;
  useLeastLetters?: boolean;
}) {
  const formattedDuration = formatDuration(duration, {
    format: ['hours', 'minutes', 'seconds'],
    delimiter: ':',
  });
  if (shorthand) {
    return convertDurationToShortTimeUnits(formattedDuration, useLeastLetters);
  }
  return formattedDuration;
}

export function convertTimestamp(timestamp: string) {
  const parsedDate = parseISO(timestamp);

  const dateFormatted = format(parsedDate, 'MMM dd, yyyy');
  const timeFormatted = format(parsedDate, 'hh:mm:ss aaa');

  return { dateFormatted, timeFormatted };
}

export function prepareDuration(valueInMSec: number) {
  const duration = {
    hours: Math.floor((valueInMSec || 0) / millisecondsInHour),
    minutes: Math.floor(
      ((valueInMSec || 0) % millisecondsInHour) / millisecondsInMinute
    ),
    seconds: Math.ceil(
      ((valueInMSec || 0) % millisecondsInMinute) / millisecondsInSecond
    ),
  };

  return duration;
}
