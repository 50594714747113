import { Route, Routes } from 'react-router-dom';
import App from 'App';
import SignIn from 'components/authentication/SignIn/SignIn';
import { AVAILABLE_PATHS, headerTypesEnum, pathKeys } from 'types/types';
import WrongRoute from 'components/WrongRoute/WrongRoute';
import ProtectedRoute from 'AppRoutes/ProtectedRoute';
import Header from 'components/Header/Header';
import JobsTable from 'components/JobsTable/JobsTable';
import StatusStatistics from 'components/JobsTable/StatusStatistics/StatusStatistics';
import JobsTableDetails from 'components/JobsTable/JobsTableDetails/JobsTableDetails';
import SignInPage from 'pages/SignInPage';
import JobsTablePage from 'pages/JobsTablePage';
import JobsTableDetailsPage from 'pages/JobsTableDetailsPage';
import MetricsPage from 'pages/MetricsPage';

function AppRoutes() {
  return (
    <Routes>
      {/* all clusters routes */}
      <Route
        path={AVAILABLE_PATHS.get(pathKeys.ALL_CLUSTERS_SIGN_IN)}
        element={<SignIn />}
      />
      <Route
        path={AVAILABLE_PATHS.get(pathKeys.ALL_CLUSTERS)}
        element={
          <ProtectedRoute
            redirectPath={
              AVAILABLE_PATHS.get(pathKeys.ALL_CLUSTERS_SIGN_IN) as string
            }>
            <App />
          </ProtectedRoute>
        }>
        <Route
          index
          element={
            <>
              <Header headerType={headerTypesEnum.BIGA}>
                <></>
              </Header>
              <StatusStatistics />
              <JobsTable />
            </>
          }
        />
        <Route
          path={AVAILABLE_PATHS.get(pathKeys.ALL_CLUSTERS_TABLE)}
          element={
            <>
              <Header headerType={headerTypesEnum.BIGA}>
                <></>
              </Header>
              <StatusStatistics />
              <JobsTableDetails />
            </>
          }
        />
        <Route
          path={AVAILABLE_PATHS.get(pathKeys.ALL_CLUSTERS_JOB_INFO)}
          element={
            <>
              <Header headerType={headerTypesEnum.BIGA}>
                <></>
              </Header>
              <StatusStatistics />
              <JobsTableDetails />
            </>
          }
        />
        <Route path="*" element={<WrongRoute />} />
      </Route>

      {/* single cluster routes */}
      <Route
        path={AVAILABLE_PATHS.get(pathKeys.SIGN_IN)}
        element={<SignIn />}
      />
      <Route
        path={AVAILABLE_PATHS.get(pathKeys.ROOT)}
        element={<SignInPage/>}>
        <Route index element={<JobsTablePage/>} />
        <Route
          path={AVAILABLE_PATHS.get(pathKeys.TABLE)}
          element={<JobsTableDetailsPage/>}
        />
        <Route
          path={AVAILABLE_PATHS.get(pathKeys.JOB_INFO)}
          element={<JobsTableDetailsPage/>}
        />
        <Route
          path={AVAILABLE_PATHS.get(pathKeys.METRICS)}
          element={<MetricsPage/>}
        />
        <Route path="*" element={<WrongRoute />} />
      </Route>

      <Route path="*" element={<WrongRoute />} />
    </Routes>
  );
}

export default AppRoutes;
