import React, { useContext, PropsWithChildren } from 'react';
import './Header.scss';
import { AppContext } from 'contexts/AppContext';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import useSignedURL from 'hooks/useSignedURL';
import { headerTypesEnum } from 'types/types';

type HeaderProps = {
  children: any;
  headerType: headerTypesEnum;
};

function Header({ children, headerType }: PropsWithChildren<HeaderProps>) {
  const { clusterName, pathname, rootURL, metricsURL } = useContext(AppContext);
  const intl = useIntl();
  const tableNameFromURL = pathname.split('/table/')[1];
  const { isSignedURL } = useSignedURL();

  const headerMapper: Record<headerTypesEnum, any[]> = {
    jobs: [
      {
        title: clusterName ?? '',
      },
      {
        title: !isSignedURL ? (
          <Link to={rootURL} className="header__first-line">
            {intl.formatMessage({
              id: 'header.jobs',
            })}
          </Link>
        ) : (
          intl.formatMessage({
            id: 'header.jobs',
          })
        ),
      },
      ...(tableNameFromURL ? [{ title: tableNameFromURL }] : []),
    ],
    metrics: [
      {
        title: clusterName ?? '',
      },
      {
        title: (
          <Link to={metricsURL} className="header__first-line">
            {intl.formatMessage({
              id: 'header.metrics',
            })}
          </Link>
        ),
      },
    ],
    biga: [],
  };

  function getHeaderFirstLineByType(type: headerTypesEnum) {
    return headerMapper[type] || [];
  }

  return (
    <header className="header__wrapper">
      <Breadcrumb items={getHeaderFirstLineByType(headerType)} />
      <section className="header__second-line">{children}</section>
    </header>
  );
}

export default Header;
