import React, { useState, useMemo } from 'react';
import { DatePicker, TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useGetSparkMemoryMetrics } from 'hooks/sparkDriver';
import SparkDriverMemoryChart from 'components/MetricsLayout/SparkDriverMemoryChart/SparkDriverMemoryChart';
import { filtersKeys } from 'types/types';
import './MetricsLayout.scss';
import SpinnerChidori from 'components/SpinnerChidori/SpinnerChidori';

dayjs.extend(utc);
dayjs.extend(timezone);

interface SelectedRange {
  fromDate: number;
  toDate: number;
}

const MetricsLayout: React.FC = () => {
  const intl = useIntl();

  const defaultFromDate = dayjs().subtract(24, 'hour');
  const defaultToDate = dayjs();

  const [selectedRange, setSelectedRange] = useState<SelectedRange>({
    fromDate: defaultFromDate.unix(),
    toDate: defaultToDate.unix(),
  });

  const [formatedRange, setFormatedRange] = useState(
    `${
      filtersKeys.CREATED_AT_DATE_RANGE
    }: ${defaultFromDate.toISOString()} to ${defaultToDate.toISOString()}`
  );

  const [dateRangePicker, setDateRangePicker] = useState<
    [dayjs.Dayjs, dayjs.Dayjs]
  >([defaultFromDate, defaultToDate]);

  const { data: memoryUsageAndLimit, isLoading } = useGetSparkMemoryMetrics({
    fromDate: selectedRange.fromDate,
    toDate: selectedRange.toDate,
    formatedRange,
  });

  const bytesToMB = (bytes: number) => bytes / 1024 ** 2;

  const memoryLimitsInMB =
    memoryUsageAndLimit?.MemoryLimits.map(bytesToMB) || [];
  const memoryUsedInMB = memoryUsageAndLimit?.MemoryUsed.map(bytesToMB) || [];
  const timeData = memoryUsageAndLimit?.TimeData;

  const rangePresets: TimeRangePickerProps['presets'] = [
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past15Minutes' }),
      value: [dayjs().subtract(15, 'minute'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past1Hour' }),
      value: [dayjs().subtract(1, 'hour'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past6Hours' }),
      value: [dayjs().subtract(6, 'hour'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past12Hours' }),
      value: [dayjs().subtract(12, 'hour'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past24Hours' }),
      value: [dayjs().subtract(24, 'hour'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past7Days' }),
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past14Days' }),
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past30Days' }),
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past60Days' }),
      value: [dayjs().subtract(60, 'day'), dayjs()],
    },
    {
      label: intl.formatMessage({ id: 'metrics.dateRange.past90Days' }),
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];

  const handleDateChange = (
    values: any,
    [startDate, endDate]: [string, string]
  ) => {
    const isFieldValueSet = !!(startDate && endDate);
    const today = dayjs().startOf('day');
    const endDateDayjs = dayjs(endDate).startOf('day');

    if (isFieldValueSet) {
      const fromDate = dayjs(values[0]);

      const toDate =
        endDateDayjs.isSame(today, 'day') || endDateDayjs.isAfter(today, 'day')
          ? dayjs()
          : dayjs(endDate).endOf('day');

      const fromDateUnix = fromDate.unix();
      const toDateUnix = toDate.unix();

      const fromDateModifiedISO = fromDate.toISOString();
      const toDateModifiedISO = toDate.toISOString();

      setSelectedRange({
        fromDate: fromDateUnix,
        toDate: toDateUnix,
      });

      setFormatedRange(
        `${filtersKeys.CREATED_AT_DATE_RANGE}: ${fromDateModifiedISO} to ${toDateModifiedISO}`
      );

      setDateRangePicker([fromDate, toDate]);
    }
  };

  const hasData = memoryLimitsInMB.length > 0 || memoryUsedInMB.length > 0;

  return (
    <section className="metrics-layout">
      <div className="metrics-layout__filters">
        <DatePicker.RangePicker
          className="metrics-layout__filters-range"
          presets={rangePresets}
          defaultPickerValue={dateRangePicker}
          value={dateRangePicker}
          onChange={handleDateChange}
        />
      </div>
      <div className="metrics-layout__chart">
        {isLoading && <SpinnerChidori />}
        {!isLoading && !hasData && (
          <div>No data available for the selected range</div>
        )}
        {!isLoading && hasData && (
          <SparkDriverMemoryChart
            allocatedMemory={memoryLimitsInMB}
            usedMemory={memoryUsedInMB}
            timeData={timeData}
          />
        )}
      </div>
    </section>
  );
};

export default MetricsLayout;
