import { useLocation } from 'react-router-dom';
import {
  adminPathName,
  AVAILABLE_PATHS,
  menuItemKey,
  pathKeys,
} from 'types/types';

function useChidoriRoutes() {
  const { pathname } = useLocation();

  const pathParts = pathname.split('/');
  const clusterNameFromURL = pathParts[2];
  const tableNameFromURL = pathname.split('/table/')[1];

  const isAdmin = pathname.includes(adminPathName);

  const rootURLpattern = new RegExp(`^/cluster/${clusterNameFromURL}/?$`);
  const adminRootURLPattern = new RegExp(`^/biga/?$`);
  const isHomePath =
    rootURLpattern.test(pathname) || adminRootURLPattern.test(pathname);

  const menuUrlMapping = new Map<menuItemKey, string>([
    [
      menuItemKey.JOBS,
      (AVAILABLE_PATHS.get(pathKeys.ROOT) as string).replace(
        ':clusterName',
        clusterNameFromURL
      ),
    ],
    [
      menuItemKey.METRICS,
      (AVAILABLE_PATHS.get(pathKeys.METRICS) as string).replace(
        ':clusterName',
        clusterNameFromURL
      ),
    ],
  ]);
  const urlMenuMapping = new Map<string, menuItemKey>();
  menuUrlMapping.forEach((value, key) => urlMenuMapping.set(value, key));
  const defaultMenuKey = urlMenuMapping.get(pathname) || menuItemKey.JOBS;

  return {
    clusterNameFromURL,
    tableNameFromURL,
    isAdmin,
    isHomePath,
    menuUrlMapping,
    urlMenuMapping,
    defaultMenuKey,
  };
}

export default useChidoriRoutes;
